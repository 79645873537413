import React from "react";

import { FaThumbsUp } from "react-icons/fa";

import Layout from "../../components/layout";
import heroImage from "../../images/contatti/0.jpg";

const ThanksPage = () => {
  return (
    <Layout pageClass="thanks">
      <section
        className="hero is-large"
        style={{
          background: `url(${heroImage}) no-repeat center center`,
          backgroundSize: "cover",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div className="hero-body">
          <h1 className="title is-1 has-text-white has-text-centered">
            La Fucina dei Talenti
          </h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns m-6">
            <div className="column has-text-centered">
              <h1 className="title is-2">
                Grazie per averci contattato <FaThumbsUp className="pb-4" />
              </h1>
              <h2 className="subtitle">Avrete presto nostre notizie...</h2>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ThanksPage;
